@import ~assets/styles/variables

.manager-queue-page
  background: radial-gradient(circle at 50% 50%,#1a6b7d, #00496e, $dark-blue )
  min-height: 100vh
  padding: 30px 0
  width: 100%
  padding: 25px
  overflow: hidden
  position: relative
  &::before
    content: ""
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    background: url('~assets/images/logo-svg.svg') center center no-repeat
    background-size: 33%
    opacity: .1
  &__header
    border-bottom: 2px solid $white
    padding-bottom: 20px
    width: 100%
    display: flex
    justify-content: space-between
  &__action
    display: flex
    &-clear
      margin-right: 15px!important
      padding: 5px 30px!important
      width: fit-content
      background: $dark-blue!important
    &-input
      margin-right: 20px!important
      min-width: 250px!important
      & input, & label, & div
        color: $white!important
      & svg path
        fill: $white
      & fieldset
        border-color: $white!important
        color: $white!important
    &-button
      padding: 5px 30px!important
      width: fit-content
      background: $dark-green!important
  &__title
    color: $white
  &__action-logout
    cursor: pointer
    margin: auto 0
    margin-left: 15px
    position: relative
    z-index: 22
