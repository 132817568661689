.reports-page
  &__title
    margin-bottom: 20px!important
  &__generation
    display: flex
    align-items: center
    & button
      width: 50%
      &:nth-child(2)
        margin-left: 10px
  &__row
    display: flex
    align-items: center
    justify-content: flex-end
    margin-bottom: 20px
  &__container
    display: flex
    align-items: center
  &__wrap
    margin-right: auto!important
  &__field
    margin-left: 10px!important
  &__indent
    margin: 0 10px!important