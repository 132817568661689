@import '~assets/styles/variables.sass'
  
.exam-page-by-id
  padding: 45px 0
  flex: 1
  &__title
    font: 1.6rem $m
    text-transform: uppercase
    margin-bottom: 15px
  &__item
    font: 1.4rem $m
    padding: 6px 15px
    color: $white
    text-transform: uppercase
    border-radius: 3px
    background: #1a6b7d
    margin-bottom: 15px
    display: flex
    align-items: center
    justify-content: space-between
  .quantity-display
    color: $white
    font: 2.2rem $m
    text-transform: uppercase
    background: none
  .quantity-picker
    display: flex
    align-items: center
    border: none
  .quantity-modifier
    border-radius: 5px 0 0 5px
    background: $white
    color: $black
    font: 1.8rem $b
  .modifier-right
    border-radius: 0 5px 5px 0
  &__text
    margin-right: 30px
  &__total
    font: 2.5rem $b