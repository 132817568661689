.class-rooms-page
  &__filter
    margin-bottom: 20px!important
  &__row
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: 20px
  &__link
    margin-right: 10px
