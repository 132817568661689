// @font-face
//   font-family: 'HelveticaNeueCyr-Roman'
//   src: url('../fonts/HelveticaNeueCyr-Roman.eot')
//   src: url('../fonts/HelveticaNeueCyr-Roman.eot?#iefix') format('embedded-opentype'), url('../fonts/HelveticaNeueCyr-Roman.woff2') format('woff2'), url('../fonts/HelveticaNeueCyr-Roman.woff') format('woff'), url('../fonts/HelveticaNeueCyr-Roman.ttf') format('truetype')
//   font-weight: normal
//   font-style: normal

// @font-face
//   font-family: 'HelveticaNeueCyr-Medium'
//   src: url('../fonts/HelveticaNeueCyr-Medium.eot')
//   src: url('../fonts/HelveticaNeueCyr-Medium.eot?#iefix') format('embedded-opentype'), url('../fonts/HelveticaNeueCyr-Medium.woff2') format('woff2'), url('../fonts/HelveticaNeueCyr-Medium.woff') format('woff'), url('../fonts/HelveticaNeueCyr-Medium.ttf') format('truetype')
//   font-weight: normal
//   font-style: normal  

// @font-face
//   font-family: 'HelveticaNeueCyr-Bold'
//   src: url('../fonts/HelveticaNeueCyr-Bold.eot')
//   src: url('../fonts/HelveticaNeueCyr-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/HelveticaNeueCyr-Bold.woff2') format('woff2'), url('../fonts/HelveticaNeueCyr-Bold.woff') format('woff'), url('../fonts/HelveticaNeueCyr-Bold.ttf') format('truetype')
//   font-weight: normal
//   font-style: normal

// @font-face
//   font-family: 'HelveticaNeueCyr-Heavy'
//   src: url('../fonts/HelveticaNeueCyr-Heavy.eot')
//   src: url('../fonts/HelveticaNeueCyr-Heavy.eot?#iefix') format('embedded-opentype'), url('../fonts/HelveticaNeueCyr-Heavy.woff2') format('woff2'), url('../fonts/HelveticaNeueCyr-Heavy.woff') format('woff'), url('../fonts/HelveticaNeueCyr-Heavy.ttf') format('truetype')
//   font-weight: normal
//   font-style: normal

@font-face
  font-family: 'OpenSans-Regular'
  src: url('../fonts/OpenSans-Regular.eot')
  src: url('../fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype'), url('../fonts/OpenSans-Regular.woff') format('woff'), url('../fonts/OpenSans-Regular.ttf') format('truetype')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'OpenSans-Semibold'
  src: url('../fonts/OpenSans-Semibold.eot')
  src: url('../fonts/OpenSans-Semibold.eot?#iefix') format('embedded-opentype'), url('../fonts/OpenSans-Semibold.woff') format('woff'), url('../fonts/OpenSans-Semibold.ttf') format('truetype')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'OpenSans-Bold'
  src: url('../fonts/OpenSans-Bold.eot')
  src: url('../fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/OpenSans-Bold.woff') format('woff'), url('../fonts/OpenSans-Bold.ttf') format('truetype')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'Arial-BoldMT'
  src: url('../fonts/Arial-BoldMT.eot')
  src: url('../fonts/Arial-BoldMT.eot?#iefix') format('embedded-opentype'), url('../fonts/Arial-BoldMT.woff') format('woff'), url('../fonts/Arial-BoldMT.ttf') format('truetype')
  font-weight: normal
  font-style: normal
