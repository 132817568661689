@import '~assets/styles/variables'
.create-official-report-transaction-page
  &__title
    margin-bottom: 30px!important
  &__textarea
    height: 150px!important
    width: 100%
    margin-bottom: 20px
    font: 1.4rem/2rem $r
    padding: 10px
  &__list
    display: flex
    align-items: center
    flex-wrap: wrap
    justify-content: space-between
    margin-bottom: 30px
  &__ticket
    width: calc(33.33% - 10px)
  .ticket-file-uploader
    margin-bottom: 20px