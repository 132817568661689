@import ~assets/styles/variables

.new-application-notice
  position: fixed
  bottom: 30px
  right: 30px
  border-radius: 5px
  background: $dark-blue
  z-index: 99
  padding: 20px
  &__title
    color: $white
  &__actions
    display: flex
    align-items: center
    justify-content: flex-end
    margin-top: 20px
    & button:first-child
      margin-right: 20px
      background: gray
    & button:last-child
      background-color: $dark-green!important
      border-color: $dark-green!important
      &:hover
        background: $dark-green!important
    & a
      color: $white
      text-decoration: none
