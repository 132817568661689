@import '~assets/styles/variables.sass'
.app-qr-modal
  max-width: 320px
  margin: 30px auto
  display: inline-block
  vertical-align: middle
  position: relative
  background: #fff
  width: 100%
  border-radius: 8px
  z-index: 132
  overflow: hidden
  padding: 45px 10px
  @media (max-width: $sm)
    max-width: 100%
  &__qr-code
    margin-bottom: 25px
  &__close
    position: absolute
    right: 10px
    top: 10px
    cursor: pointer
    width: 25px!important
    height: 25px!important
  &__title
    font: 3.2rem $b
    margin-bottom: 5px
  &__date
    font: 2rem $r