

.update-vising-page
  max-width: 800px
  &__title
    margin-bottom: 30px!important
  &__button
    width: 100%
    a
      text-decoration: none!important
      width: 100%