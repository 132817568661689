@import '~assets/styles/variables.sass'
.sidebar
  max-width: 300px
  width: 100%
  border-radius: 6px
  display: flex
  @media (max-width: $xxl)
    max-width: 250px
  @media (max-width: $lg)
    order: 2
    padding-top: 10px
    max-width: 30px
  @media (max-width: $md)
    padding-top: 0
  @media (max-width: $sm)
    max-width: 100%
    display: flex
    justify-content: flex-end
    padding-top: 0
    padding-bottom: 15px
  &--active
    .sidebar__wrap
      @media (max-width: $lg)
        visibility: visible
        opacity: 1
        z-index: 10
    .sidebar__link
      &:hover
        .sidebar__inner
          visibility: visible
          opacity: 1
  &__wrap
    flex: 1
    display: flex
    @media (max-width: $lg)
      position: fixed
      left: 0
      top: 0
      width: 100%
      height: 100%
      background: rgba(0,0,0, .6)
      max-width: 100%
      justify-content: flex-end
      transition: .3s all ease
      visibility: hidden
      z-index: -1
      opacity: 0

  &__in
    flex: 1
    display: flex
    flex-direction: column
    background: #003956
    @media (max-width: $lg)
      max-width: 300px
    @media (max-width: $sm)
      max-width: 220px
  &__link
    display: flex
    align-items: center
    font: 1.6rem $m
    color: $white
    text-decoration: none
    padding: 18px 25px
    border-bottom: 3px solid #1a6b7d
    position: relative
    transition: .3s all ease
    @media (max-width: $xxl)
      font-size: 1.6rem
      padding: 15px 20px
    @media (max-width: $sm)
      font-size: 1.4rem
      padding: 10px
    &:hover
      background: #2f9ba6
      border-color: #2f9ba6
      .sidebar__inner
        opacity: 1
        visibility: visible
        .sidebar__text
          display: block
    &:active
      background: #50c4ca
      border-color: #50c4ca
    &--inner
      padding: 5px 20px
      height: 69px
      @media (max-width: $sm)
        height: 53px
        padding: 5px 10px
  &__inner
    position: absolute
    top: 0
    min-height: 100%
    left: calc(100% - 3px)
    background: #003956
    z-index: 2
    width: auto
    border-left: 3px solid #1a6b7d
    min-width: 70%
    opacity: 0
    visibility: hidden
    transition: .3s all ease
    @media (max-width: $sm)
      right: calc(100% - 3px)
      left: inherit
      min-width: 60%
  &__icon
    margin-right: 10px
    @media (max-width: $sm)
      margin-right: 7px
      max-width: 20px
  &__toggle
    cursor: pointer
    display: block
  &__toggle-wrap
    display: none
    @media (max-width: $lg)
      display: flex
      justify-content: flex-end
      margin-left: auto
  &__close-wrap
    padding: 25px 15px 0 0
    display: none
    @media (max-width: $lg)
      display: flex
      justify-content: flex-end
  &__close
    width: 40px
    height: 40px
    cursor: pointer
    margin-bottom: 35px
    span
      height: 3px
      display: block
      margin: 10px 0
      -webkit-transition: all .3s ease
      transition: all .3s ease
      background: $white
      &:nth-child(1)
        transform: rotate(45deg) translate3d(10px,9px,0)
      &:nth-child(2)
        opacity: 0
      &:nth-child(3)
        transform: rotate(-45deg) translate3d(9px,-7px,0)
  &__hamburger
    cursor: pointer
