@import ~assets/styles/variables

.create-ticket-page
  &__modal-overlay
    position: absolute
    left: 0
    top: 0
    bottom: 0
    rigth: 0
    width: 100%
    height: 100vh
    background: rgba(0,0,0, 0.7)
    z-index: 9
  &__modal
    max-width: 900px
    height: 800px
    position: fixed
    border: 1px solid gray
    padding: 60px
    border-radius: 10px
    z-index: 10
    left: 0
    top: 0
    bottom: 0
    right: 0
    background: $white
    margin: auto
    overflow: hidden
    & div > div
      left: 10px!important
      right: auto!important
  &__list
    width: 100%
    display: flex
    grid-gap: 20px
    flex-wrap: wrap
    margin-top: 20px
  &__list-item
    width: 200px
    border-radius: 4px
    border: 1px solid gray
    & svg
      width: 100%
      height: 100%
    & img
      width: 100%
      height: 100%
      cursor: pointer
      object-fit: cover
