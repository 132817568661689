@import '~assets/styles/variables.sass'
#root
  .MuiTextField-root
    display: block
    width: 100%

  .MuiInputBase-root
    background: none
    width: 100%
  .MuiInputBase-input 
    font: 1.9rem $r
    color: $black
  .MuiFormLabel-root
    color: rgba(0,0,0,.5)
    font: 1.2rem $r
  .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root
    transform: translate(14px, 13px) scale(1)
    @media (max-width: $sm)
      transform: translate(12px, 17px) scale(1)
  .MuiTextField-root
    .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root
      transform: translate(14px, 20px) scale(1)
  .css-1kty9di-MuiFormLabel-root-MuiInputLabel-root
    transform: translate(14px, -7px) scale(0.75)
  // .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root
  //   transform: translate(14px, 13px) scale(1)
  .MuiOutlinedInput-notchedOutline
    border-color: rgba(0,0,0, .5)
    border-radius: 4px
  .Mui-focused
    .MuiOutlinedInput-notchedOutline
      border-color: rgba(0,0,0, .5)
      border-width: 1px
  .MuiButton-sizeMedium
    font: 1.3rem $m
  .MuiButton-containedInfo
    background: #1a6b7d
  .MuiButton-containedSuccess
    background: #003956
    border: 2px solid #003956
    span
      display: none!important
    &:hover
      background: $white!important
      color: #003956!important
    &:active
      background: #003956
  .MuiButton-containedSecondary
    background: #ff0000
    border: 2px solid #ff0000
    span
      display: none!important
    &:hover
      color: #ff0000
      background: $white!important
  .Mui-disabled
    background: #d9dada!important
    border-color: #d9dada!important

  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input
    @media (max-width: $sm)
      padding-top: 14px!important
      padding-bottom: 14px!important
  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline
    border-color: #ff0000
  .MuiFormControl-root
    .Mui-error 
      color: #ff0000!important
  .css-1mog9k0-MuiFormLabel-root-MuiInputLabel-root
    transform: translate(12px, 25px) scale(1)
  .MuiButton-sizeLarge
    font: 1.5rem $m
  .MuiButton-containedInherit
    background: #d9dada
    color: #2f9ba6
    border: 2px solid #d9dada

  .MuiTableCell-head
    font: 1.5rem $m
    color: $black
  .css-16c50h-MuiInputBase-root-MuiTablePagination-select
    width: auto!important
  .MuiTableCell-body
    font: 1.4rem $r
    color: $black
  .MuiTablePagination-displayedRows
    font: 1.5rem $r
    color: $black
  .MuiTablePagination-selectLabel
    font: 1.4rem $r
    color: $black
  .css-194a1fa-MuiSelect-select-MuiInputBase-input
    display: flex
    align-items: center

  .MuiIconButton-colorPrimary
    background: #d9dada
    transition: .3s all ease
    &:hover
      background: #8cd3d8
  .MuiIconButton-colorError
    background: rgba(255,0,0, .3)
    transition: .3s all ease
    &:hover
      background: rgba(255,0,0, .5)
  .MuiPaper-rounded
    overflow-x: auto
    padding-bottom: 20px
  .MuiFormControlLabel-label
    font: 1.4rem $r
  .select-small .MuiFormLabel-colorPrimary[data-shrink="false"]
    transform: translate(14px, 13px) scale(1)
  .MuiTablePagination-root
    .MuiInputBase-root
      width: auto!important

.MuiInputLabel-sizeSmall[data-shrink="false"]
  transform: translate(14px, 12px) scale(1)
       
