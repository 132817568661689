@import ~assets/styles/variables

.create-application-page-by-user
  &__button
    width: 100%
    text-decoration: none
  &__button-wrap
    margin-top: 25px!important
  .MuiTypography-h3
    margin-bottom: 20px
  &__select
    &--error
      border-color: #ff0000!important
      &::placeholder
        color: #ff0000
  .react-datepicker__input-container
    border: none!important
  input.create-application-page-by-user__calendar
    width: 100%
    display: block
    padding: 8.5px 14px
    width: 100%
    height: 2.4375em
    font: 1.7rem $r
    border-radius: 4px
    border: 1px solid rgb(118, 118, 118)
    width: 100%
  .react-datepicker-wrapper
    width: 100%
  .react-datepicker__triangle
    transform: translate3d(140px, 0, 0)!important
    padding: 0!important
  .react-datepicker__navigation-icon
    top: -2px!important
    &::before
      height: 7px!important
      width: 7px!important
  &__calendar-title
    display: block
    margin-bottom: 10px
