@import '~assets/styles/variables.sass'


.lang
  &__item
    position: relative
    text-transform: uppercase
    padding: 8px 11px
    background: #1a6b7d
    color: $white
    cursor: pointer
    font: 1.6rem $m
    @media (max-width: $xl)
      font-size: 1.4rem
      padding: 6px 9px
    &:hover
      .lang__dropdown
        visibility: visible
        opacity: 1
        z-index: 1
  &__dropdown
    position: absolute
    top: 100%
    height: auto
    left: 0
    width: 100%
    opacity: 0
    visibility: hidden
    z-index: -1
  &__link
    display: block
    padding: 4px 11px
    background: #2f9ba6
    cursor: pointer
    text-transform: uppercase
    transition: .3s all ease
    font: 1.6rem $r
    @media (max-width: $xl)
      font-size: 1.4rem
      padding: 4px 9px
    &:hover
      background: #50c4ca