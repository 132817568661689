@import '../../assets/styles/variables'

.confirmation
  font-size: 0
  position: fixed
  left: 0
  top: 0
  bottom: 0
  right: 0
  background: rgba(0, 0, 0, 0.6)
  z-index: 100
  display: block
  overflow-y: auto
  text-align: center
  padding: 30px 40px
  transition: .4s all ease
  &::before
    content: ""
    display: inline-block
    vertical-align: middle
    height: 100%
    width: 0
  &__blackout
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
  &__window
    max-width: 575px
    margin: 30px auto
    display: inline-block
    vertical-align: middle
    position: relative
    background: #eee
    width: 100%
    border-radius: 5px
    overflow: hidden
    padding: 45px 35px
  &__title
    font: 2.8rem $b
    text-align: left
    color: #1d1d1b
    margin-bottom: 20px
  & .confirmation__exit
    position: absolute
    right: 15px
    top: 15px
    font-size: 3rem!important
    cursor: pointer
  &__text
    font: 14px $m
    color: #1d1d1d
    text-align: left
  &__btn-row
    display: flex
    justify-content: space-between
    align-items: center
    margin-top: 30px
  &__btn
    width: calc(50% - 10px)!important
    position: relative!important
