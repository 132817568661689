@import ~assets/styles/variables

.read-application-by-user
  .react-datepicker__input-container
    border: none!important
  input.read-application-by-user__calendar
    width: 100%
    display: block
    padding: 8.5px 14px
    width: 100%
    height: 2.4375em
    font: 1.7rem $r
    border-radius: 4px
    border: 1px solid rgb(118, 118, 118)
  .react-datepicker__triangle
    transform: translate3d(140px, 0, 0)!important
    padding: 0!important
  .react-datepicker__navigation-icon
    top: -2px!important
    &::before
      height: 7px!important
      width: 7px!important
  &__calendar-title
    display: block
    margin-bottom: 10px
  &__date
    margin-top: 20px!important
  &__qr-button
    margin-top: 20px
    display: flex
    justify-content: flex-end
