@import ~assets/styles/variables

.create-category-status
  max-width: 700px
  margin: 30px auto
  display: inline-block
  vertical-align: middle
  position: relative
  background: $white
  width: 100%
  border-radius: 8px
  overflow: hidden
  padding: 35px
  &__form
    margin-top: 15px!important
  &__field
    text-align: left
  &__button
    background: $dark-blue!important
    height: 100%
    &--cancel
      background: #d9dada!important
      color: #2f9ba6!important
      border: 2px solid #d9dada!important
