@import '~assets/styles/variables.sass'
.token-modal
  max-width: 767px
  margin: 30px auto
  display: inline-block
  vertical-align: middle
  position: relative
  background: #d9dada
  width: 100%
  border-radius: 8px
  overflow: hidden
  padding: 55px 25px 35px
  &__token
    font: 1.8rem $r
    color: #2f9ba6
    cursor: pointer
    word-break: break-all
  &__textarea
    height: 0
    overflow: hidden
    opacity: 0
    z-index: -1
    position: absolute
  &__button
    margin-top: 20px!important
  &__exit
    position: absolute
    top: 15px
    right: 20px
    cursor: pointer