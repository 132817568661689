@import '~assets/styles/variables.sass'
#root
    
  .MuiAlert-root
    min-width: 300px
    padding-top: 15px
    padding-bottom: 15px
    position: fixed
    right: 20px
    bottom: 20px
    z-index: 2000
  .MuiAlert-message
    font: 1.8rem $m
  .MuiAlert-standardInfo
    color: #003956!important
    svg
      path
        fill: #003956