.appeal-page-by-id
  &__title
    margin-bottom: 25px!important
  &__photo-wrap
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)
    border-radius: 5px
    padding: 5px
  &__photo
    width: 100%
    border-radius: 5px
  &__link
    text-decoration: none
    color: red
