@import '~assets/styles/variables.sass'
  
.users-page
  .css-i4bv87-MuiSvgIcon-root
    width: 26px
    height: 26px
    cursor: pointer
  .css-zylse7-MuiButtonBase-root-MuiIconButton-root
    padding: 0
  &__button
    width: 100%
  &__form
    margin-bottom: 35px
  
  &__statues-btn
    cursor: pointer
  &__checked-btn
    display: block!important
    margin: 0 auto
  &__row
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: 20px