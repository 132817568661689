@import '~assets/styles/variables.sass'

.exam-sheet
  display: none



@media print
  .update-application-page__actions
    display: none
  .middle__content
    width: 100%!important
  .update-application-page__title-sub--verified
    display: none!important
  .update-application-page__more
    display: none
  .popup
    position: static!important
  .sidebar
    display: none!important
  .header
    display: none!important
  .middle
    padding: 0
  .footer
    display: none!important
  .popup
    padding: 0
    margin: 0
    display: block
    position: static
    &::before
      display: none
  .update-application-page__header-top
    display: none!important
  .timeline__item
    padding: 0!important
    display: none
  .timeline__in
    margin-bottom: 0!important
    display: block!important
  .update-application-page__form
    display: none
  .update-application-page__status-info--red
    display: none
  .update-application-page__status-info
    display: none
  .results-modal
    display: none!important
  .exam-sheet
    width: 100%
    display: block
    visibility: visible
    &__block
      page-break-after: auto!important
    &__title
      font: 13px $r
      text-transform: uppercase
      text-align: center
      line-height: 15px
    &__row
      display: flex
      justify-content: space-between
    &__item
      margin-top: 10px
      &:first-child
        min-width: 40%
      &:nth-child(2)
        margin-left: 35px
    &__wrap
      margin-bottom: 5px
      display: flex
      align-items: center
    &__text
      font: 11px $r
      line-height: 13px
      &:first-child
        margin-right: 5px
    &__table
      page-break-after: auto!important
    &__tr
      &::after
        content: ""
        display: block
        page-break-after: auto
        page-break-inside: auto
        page-break-before: auto
        height: 0
        overflow: hidden
    &__td
      border: 1px solid rgba(0,0,0, .7)
      font: 10px $r
      color: black
      padding: 6px 3px

@page
  size: A4
  margin: 0
