@import '~assets/styles/variables.sass'
.user-identification-page
  height: 100%
  display: flex
  flex-direction: column
  &__container
    display: flex
    align-items: flex-start
    justify-content: space-between
    flex: 1
    @media (max-width: $sm)
      flex-direction: column
      align-items: flex-start
  &__left
    max-width: 200px
    width: 100%
    @media (max-width: $sm)
      max-width: 100%
      display: flex
  &__avatar-wrap
    box-shadow: 0 10px 30px 0 rgb(16 36 94 / 20%)
    border-radius: 5px
    border-radius: 2px
    position: relative
    width: 100%
    @media (max-width: $sm)
      max-width: 120px
      margin-right: 15px
    &::before
      content: ""
      padding-bottom: 125%
      display: block
      width: 100%
  &__avatar
    border-radius: 5px
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    object-fit: cover
  &__confirm
    box-shadow: 0 10px 30px 0 rgb(16 36 94 / 20%)
    padding: 10px 15px
    border-radius: 5px
    color: $white
    font: 1.5rem $m
    text-transform: uppercase
    margin-top: 20px
    background: green
    @media (max-width: $sm)
      flex: 1
      margin-top: 0
    &--error
      background: #ff0000
  &__info
    width: calc(100% - 220px)
    height: 100%
    box-shadow: 0 10px 30px 0 rgb(16 36 94 / 20%)
    border-radius: 5px
    padding: 12px
    @media (max-width: $sm)
      width: 100%
      margin-top: 15px
      height: auto
  &__table
    display: table
    width: 100%
  &__row
    display: table-row
  &__td
    display: table-cell
    font: 1.5rem $r
    color: $black
    padding: 10px
    text-transform: uppercase
    &:first-child
      font-family: $b
  &__hidden
    height: 0
    overflow: hidden
    opacity: 0
    z-index: -1