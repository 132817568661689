@import '~assets/styles/variables.sass'

.calendar-modal
  max-width: 767px
  margin: 30px auto
  display: inline-block
  vertical-align: middle
  position: relative
  background: #fff
  width: 100%
  border-radius: 8px
  z-index: 132
  overflow: hidden
  padding: 55px 35px 35px
  @media (max-width: $sm)
    padding: 15px
  &__button
    width: 100%
    margin-top: 25px!important
  .MuiTypography-h3
    margin-bottom: 12px
    text-align: left
  &__close
    position: absolute
    right: 10px
    top: 10px
    width: 25px!important
    height: 25px!important
    cursor: pointer
  &__timepicker
    margin-top: 15px
    width: 100%
    text-align: left
    .react-time-picker__wrapper
      border-radius: 4px
    .react-time-picker__inputGroup
      padding: 8.5px 14px!important
      span
        font: 1.6rem $r
        color: $black
      input
        font: 1.6rem $r
        color: $black
        // min-width: 25px
        &::placeholder
          font: 1.6rem $r
          color: $black