@import ~assets/styles/variables
.user-modal
  position: absolute
  left: 0
  top: 0
  bottom: 0
  right: 0
  margin: auto
  max-width: 1024px
  height: fit-content
  &__title
    display: block
    text-align: left
    margin-bottom: 0!important
  &__icon
    width: 40px
    height: 40px
    border: 1px solid gray
    padding: 5px
    cursor: pointer
    display: block
    svg
      width: 100%
      height: 100%
      path
        fill: gray
  &__paper
    padding: 20px
  &__item
    font-size: 2rem!important
    text-align: left!important
    display: block
    &--err
      color: $red
      text-decoration: underline
  &__item-container
    margin-top: 15px!important
  &__buttons
    margin-top: 15px!important
  &__photo
    width: 200px
    height: 200px
    margin: 0 auto
    border: 1px solid $dark-blue
    img
      height: 100%
      width: auto
      object-fit: contain
