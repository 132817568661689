@import '~assets/styles/variables.sass'

.timeline
  width: 100%
  &__in
    display: flex
    margin: 0 -10px
    margin-bottom: 25px
    justify-content: space-between
    align-items: flex-start
    position: relative
    @media (max-width: $md)
      flex-direction: column
      align-items: flex-start
    &::before
      content: ''
      position: absolute
      left: 10px
      right: 75px
      top: 24px
      height: 2px
      background: #d9dada
      @media (max-width: $md)
        left: 34px
        width: 2px
        height: auto
        top: 0
        bottom: 15px
  &__content
    background: #d9dada
    min-width: 50px
    min-height: 50px
    display: inline-block
    max-width: 50px
    max-height: 50px
    border-radius: 50%
    display: flex
    align-items: center
    justify-content: center
    font: 1.6rem $m
    color: $black
    margin: 0 auto
    @media (max-width: $md)
      margin-right: 20px
  &__item
    position: relative
    text-align: center
    padding: 0 10px
    cursor: pointer
    @media (max-width: $md)
      display: flex
      align-items: center
      margin-bottom: 15px
    &--active
      .timeline__content
        background: yellow!important
        color: $black!important
    &--not-pass
      .timeline__content
        background: rgba(255, 0, 0, 1)
        color: $white
    &--passed
      .timeline__content
        background: green
        color: $white
    &--none
      display: none
    &--theory
      .timeline__content
        background: #1a6b7d
    &-info
      position: absolute
      width: 300px
      margin: 0 auto
      right: 0
      top: 100%
      background: $dark-blue
      color: $white
      padding: 20px
      z-index: 2
      max-height: 300px
      overflow-y: auto
      &::-webkit-scrollbar
        width: 10px
      &::-webkit-scrollbar-thumb
        border-radius: 4px
        background: $dark-green
      &-result
        margin-top: 15px!important
        display: flex!important
        align-items: center!important
        &-label
          margin-right: 20px!important
      &-score
        margin-top: 20px!important
        text-decoration: underline
      &-practice-passed
        h4
          text-decoration: underline
  &__theory-failed
    font: 1.6rem $m
    color: $red