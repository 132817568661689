@import '~assets/styles/variables.sass'
.payment-page
  &__row
    margin-bottom: 20px
  &__sum
    margin-top: 25px!important
  &__type
    display: flex
    align-items: center
    height: 100%
    border-radius: 5px
    overflow: hidden
  &__method
    width: 50%
    height: 35px
    display: flex
    align-items: center
    justify-content: center
    background: #696a6a
    color: #fff
    cursor: pointer
    &:not(:last-child)
      border-right: 1px solid #fff
    &--active
      background: #2f9ba6
  .MuiTypography-h3
    margin-bottom: 20px
  &__icon
    max-width: 22px
    width: 100%
    margin-right: 5px
    path
      fill: #fff
  &__paper
    margin-top: 25px
  &__certificate
    display: none
  &__button
    width: 100%
  &__warning
    color: $red!important
    font-family: $b!important