@import './normalize.scss'
@import './variables.sass'
@import './fonts.sass'
@import './material.sass'

html, body
  height: 100%
  font-size: 10px
  @media (max-width: $xl)
    font-size: 9.5px

body
  margin: 0
  -webkit-print-color-adjust: exact!important

#root
  min-height: 100%
  position: relative
  display: flex
  flex-direction: column
  overflow: hidden

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button,
input::-webkit-inner-spin-button,
input::-webkit-calendar-picker-indicator
  display: none
  -webkit-appearance: none
  -webkit-appearance: none
  margin: 0

/* Firefox */
input[type=number]
  -moz-appearance: textfield

.middle
  flex: 1
  display: flex
  padding: 45px 0
  @media (max-width: $sm)
    padding: 35px 0
  &__container
    display: flex
    flex: 1
    align-items: flex-start
    justify-content: space-between
    flex: 1
    @media (max-width: $sm)
      flex-direction: column
  &__content
    width: calc(100% - 330px)
    height: 100%
    @media (max-width: $xxl)
      width: calc(100% - 280px)
    @media (max-width: $lg)
      width: calc(100% - 60px)
    @media (max-width: $sm)
      width: 100%
      order: 2
  &--close
    @media (min-width: 1200px)
      .sidebar
        width: 80px
        @media (max-width: $xxl)
          width: 70px
      .sidebar__text
        display: none
      .sidebar__icon
        margin-right: 0
      .middle__content
        width: calc(100% - 110px)
        @media (max-width: $xxl)
          width: calc(100% - 100px)

img
  max-width: 100%
  height: auto

.container
  max-width: 1670px
  margin: 0 auto
  padding: 0 35px
  width: 100%
  @media (max-width: $xxl)
    max-width: 1440px
  @media (max-width: $sm)
    padding-left: 15px
    padding-right: 15px

.table-wrap
  overflow-x: auto

*
  box-sizing: border-box
  outline: 0!important

.css-1z7n62
  font-size: 0.97rem!important

.btn-row
  display: flex
  align-items: center

.delete-icon
  margin-left: 12px
  cursor: pointer

.edit-icon
  cursor: pointer

.btn-center
  display: block!important
  margin: 0 auto

.css-1q60rmi-MuiAutocomplete-endAdornment
  top: auto!important

.last-column
  position: sticky
  right: 0
  z-index: 1
  background: #fff

.is-block-user
  background: rgba(141, 17, 30, 0.859)

.MuiOutlinedInput-notchedOutline legend
  font-size: 1rem

.react-datepicker__time-list-item--disabled
  display: none
.react-datepicker__time-list-item
  padding-top: 10px!important
