@import ~assets/styles/variables
.verification-modal
  max-width: 720px
  margin: 30px auto
  display: inline-block
  vertical-align: middle
  position: relative
  width: 100%
  border-radius: 8px
  overflow: hidden
  &__button
    position: absolute
    right: 10px
    top: 10px
    width: 45px
    height: 45px
    background: url('~assets/images/camera.svg') center center no-repeat
    background-size: 35px auto
    z-index: 2
    border: none
    cursor: pointer
