@import '~assets/styles/variables.sass'

.results-modal
  max-width: 991px
  margin: 30px auto
  display: inline-block
  vertical-align: middle
  position: relative
  background: #fff
  width: 100%
  border-radius: 8px
  z-index: 132
  overflow: hidden
  padding: 55px 35px 35px
  @media (max-width: $sm)
    padding: 15px
  &__details
    margin-bottom: 25px
    font: 1.5rem $r
    color: $black
    overflow-x: auto
  &__link
    width: fit-content!important
    margin-left: auto
    padding: 0!important
    a
      text-decoration: none
      color: $dark-blue
      display: block
      padding: 10px
      transition: all 0.3s ease
      &:hover
        background: $dark-blue
        color: $white
  &__item
    font: 1.8rem $b
    text-transform: uppercase
    text-align: center
  &__block
    display: flex
  &__table
    display: table
    margin-top: 20px!important
  &__block
    display: table-row
  &__wrap
    display: table-cell
  &__attemps
    padding: 10px
    border-radius: 5px
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)
    margin-bottom: 20px
    font: 2rem $b
    text-transform: uppercase
  &__attemps-row
    display: flex
    align-items: center
    justify-content: center
    color: #ff0000
  &__attemps-icon
    margin-left: 5px
  &__title
    font: 2rem $b
    color: green
    text-transform: uppercase
    margin-bottom: 20px
    &--red
      color: #ff0000
  &__inner
    display: flex
    align-items: center
    flex-wrap: wrap
    margin-top: 15px
    margin-left: -7px
    margin-right: -7px
  &__answers
    font: 1.6rem $m
    padding: 0 7px
    width: 50%
    margin-bottom: 15px
    text-align: left
    @media (max-width: $md)
      width: 100%
    span
      min-width: 28px
  &__questions-wrap
    display: flex
    align-items: center
  &__icon
    margin: 0 auto
    display: block
  &__total
    display: flex
    justify-content: space-between
    align-items: center
    padding: 0 15px
  &__total-text
    font: 2rem $b
    color: $black
  &__table-inner
    overflow-x: auto
