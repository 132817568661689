.certificates-page
  &__title
    margin-bottom: 20px!important 
  &__img
    min-width: 120px
    max-width: 120px
  &__form
    margin-bottom: 20px!important
  &__update
    margin-top: 10px
    display: inline-block
    text-decoration: none
    width: 100%
    text-align: center
    padding: 6px
    background: #003956
    color: #fff
    border-radius: 4px