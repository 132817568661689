@import ~assets/styles/variables.sass
.queue-item-list
  display: grid
  grid-template-columns: repeat(auto-fill, 215px)
  grid-template-rows: repeat(auto-fill)
  grid-gap: 20px
  margin-top: 15px
  position: relative
  z-index: 1
  &__manager--practice
    padding-bottom: 650px
  &__world-icon
    margin-right: 5px
  &__item-code
    font: 2.8rem $b!important
    color: $white!important
    display: flex
    align-items: center
  &__item-name
    font: 1.7rem $b!important
    color: $white!important
  &__item
    text-decoration: none
    color: $white
    padding: 5px
    cursor: pointer
    transition: all 0.3s ease
    border-radius: 5px
    border: 2px solid $white
    position: relative
    display: flex
    flex-direction: column
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
    &--deleted
      display: none
    &--theory
      min-height: 125px
    h5
      color: $white!important
    &--selected
      color: $dark-green
      z-index: 44
      border-color: $dark-green
    &:hover
      border-color: $dark-green
      .manager-queue-page__item-name,
      .manager-queue-page__item-code
        color: $dark-green
    &--active
      background: rgb(15, 82, 0)
      border-color: rgb(15, 82, 0)
    &-user
      display: flex
      align-items: center
      flex-wrap: wrap
    &--theory-selected
      background: $dark-green
      border-color: $white!important
    &-name
      margin-top: 5px!important
      &:first-child
        margin-right: 15px
    &-name-active,
    &-code-active
      color: $dark-green
  &__context
    position: absolute
    left: 0
    top: calc(100% + 5px)
    width: 200px
    background: $white
    cursor: pointer
    opacity: 0
    visibility: hidden
    transition: all 0.3s ease
    z-index: 3
    &-cars
      position: relative
      display: flex
      align-items: center
      svg
        margin-left: 10px
      &:hover
        svg
          path
            fill: $white
    &--selected
      opacity: 1
      visibility: visible
    &-item
      padding: 10px
      &:hover
        background: $dark-green
        & h4
          color: $white
      &:not(:last-child)
        border-bottom: 2px solid $dark-green
      &-cars
        &:hover
          & .queue-item-list__context-car-item h4
            color: $dark-green
        &--selected
          background: $dark-green!important
          & .queue-item-list__context-cars h4
            color: $white!important
          svg
            path
              fill: $white
    & h4
      color: $dark-green
    &-car-list
      position: absolute
      left: calc(100% + 2px)
      top: 0
      width: 300px
      height: auto
      background: $white
      @media (max-width: $lg)
        left: 0
        top: 100%
    &-car-item
      padding: 10px
      &:not(:last-child)
        border-bottom: 2px solid $dark-green
      &:hover, &--selected
        background: $dark-green
        h4
          color: $white!important
  &__all
    font: 4.5rem $r
    color: $white
    align-self: flex-end
  &__item-category-container
    display: flex
    align-items: center
    margin-top: auto
  &__item-category
    font: 2rem $b
    color: yellow
    margin-left: 5px
  &__item-car
    font: 1.4rem $b
  &__item-class-room
    font: 2.6rem $b!important
    color: $white !important
    padding-bottom: 5px
    margin-top: auto!important

.queue-item-list-class-room
  display: flex
  flex-direction: column
  flex: 1
  &__container
    display: flex
    flex: 1
    padding-top: 20px
  &__in
    display: flex
    flex-direction: column
    padding: 0 10px
    &:not(:last-child)
      padding-left: 0
      border-right: 2px solid $white
  &__title
    color: $white
    margin-bottom: 20px!important
    text-align: center
    font-family: $b!important
  &__item
    display: grid
    grid-template-columns: repeat(auto-fill, 210px)
    grid-template-rows: min-content
    grid-gap: 20px
    position: relative
    z-index: 1
    &-without-cabinet
      margin-top: auto
      padding-top: 20px
  .queue-item-list__item--theory
    min-height: auto
  &__item-without-class-room
    border-top: 2px solid $white
    margin-top: 15px
    padding-top: 15px
    min-height: 350px

.queue-item-list-manager
  margin-top: 15px
  display: grid
  grid-template-columns: repeat(auto-fill, 210px)
  grid-template-rows: min-content
  grid-gap: 20px
  position: relative
  z-index: 1
  .queue-item-list__item--theory
    min-height: auto
