@import ~assets/styles/variables

.select-manager-queue-page
  background: radial-gradient(circle at 50% 50%,#1a6b7d, #00496e, $dark-blue )
  min-height: 100vh
  padding: 30px 0
  width: 100%
  padding: 50px
  overflow: hidden
  &__header
    padding-bottom: 15px!important
    border-bottom: 1px solid $white
  & h2
    color: $white
  &__list
    wdith: 100%
    display: flex
    flex-direction: column
    align-items: flex-start
    margin-top: 20px
  &__link
    color: $white
    text-decoration: none
    &:not(:first-child)
      margin-top: 15px!important
