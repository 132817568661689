@import '~assets/styles/variables'

.official-reports-page
  &__title
    margin-bottom: 30px!important
  &__container
    margin-bottom: 30px
  &__btn
    padding: 5px
    border-radius: 4px
    background: $dark-blue
    color: $white
    white-space: nowrap
    &:not(:first-child)
      margin-top: 10px
  &__btn-date
    font: 1.1rem/1.5rem $r
    color: $white
    margin-bottom: 2px