@import '~assets/styles/variables.sass'

.theory-exam-sheet
  display: none

@media print
  .update-application-page__actions
    display: none!important
  .middle__content
    width: 100%!important
  .update-application-page__title-sub--verified
    display: none!important
  .update-application-page__more
    display: none
  .popup
    position: static!important
    width: 100%
    left: inherit
    top: inherit
    right: inherit
    bottom: inherit
    padding: 0
  .results-modal
    display: none
  .sidebar
    display: none!important
  .header
    display: none!important
  .middle
    padding: 0
  .footer
    display: none!important
  .popup
    padding: 0
    margin: 0
    display: block
    position: static
    background: none
    &::before
      display: none
  .update-application-page__header-top
    display: none!important
  .timeline__theory-failed
    display: none
  .timeline-text
    display: none
  .timeline__item
    padding: 0!important
    display: none!important
  .timeline__in
    margin-bottom: 0!important
    display: block!important
  .update-application-page__form
    display: none
  .update-application-page__status-info--red
    display: none
  .update-application-page__status-info
    display: none
  .theory-exam-sheet
    background: none
    width: 100%
    display: block
    visibility: visible
    &__headline
      font: 20px $b
      margin-bottom: 5px
    &__row
      display: flex
      align-items: center
      justify-content: space-between
    &__text
      font: 13px $m
    &__left
      text-align: left
    &__block
      page-break-after: auto!important
    &__title
      font: 15px $m
      text-transform: uppercase
      text-align: center
      line-height: 15px
      margin-bottom: 5px
    table
      page-break-after: auto!important
      width: 100%
    tr
      &::after
        content: ""
        display: block
        page-break-after: auto
        page-break-inside: auto
        page-break-before: auto
        height: 0
        overflow: hidden
    td
      border: 1px solid rgba(0,0,0, .7)
      font: 10px $r
      color: black
      padding: 6px 3px

@page
  size: A4
  margin: 0