@import ~assets/styles/variables
.update-category-page
  &__button
    width: 100%
    text-decoration: none
  &__button-wrap
    margin-top: 25px!important
  &__statuses
    margin-bottom: 25px
  &__statuses-link
    text-decoration: none
  .MuiTypography-h3
    margin-bottom: 20px
  &__tabs
    background: $dark-blue
    width: 100%
    border-radius: 4px
    margin-bottom: 20px
    span.css-1aquho2-MuiTabs-indicator
      background-color: gray
    a
      width: 50%
      display: block
      font: 1.5rem $m
      max-width: 100%
      display: flex
      align-items: center
      color: $white
      &.Mui-selected
        color: $white
        background: #1a6b7d
        border: none
