@import ~assets/styles/variables
.pass-modal
  width: 960px
  height: fit-content
  padding: 50px
  background: $white
  position: absolute
  left: 0
  top: 0
  bottom: 0
  right: 0
  margin: auto
  &__title
    margin-bottom: 20px!important
  &__close
    width: 30px
    height: 30px
    position: absolute
    right: 15px
    top: 15px
    cursor: pointer
    svg
      width: 100%
      height: 100%
  &__button
    margin-top: 20px!important
