.school-page
  &__row
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: 20px
  &__search
    display: flex
    margin: 0 0 20px 0
    max-width: 500px
    & button
      margin-left: 10px
