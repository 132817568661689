@import '~assets/styles/variables.sass'

@media print
  .certificate
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    background: $white
    z-index: 20
    display: flex!important
    visibility: visible
  .update-application-page
    visibility: hidden
  .sidebar
    display: none!important
  .certificate__img-wrap
    flex: 1
    display: flex
    align-items: flex-start
    justify-content: flex-start
  .certificate__img
    width: 100%
    display: block
    margin: 0 auto
  .certificate__code-last
    position: absolute
    z-index: 1231232
    display: block
    right: 11.3%
    top: 10%
  .certificate__date
    position: absolute
    left: 19%
    top: 54%
    z-index: 123
    color: $black
    font: 13px $b
    text-transform: uppercase
  .certificate__full-name
    position: absolute
    left: 10%
    bottom: 71.5%
    width: 27%
    z-index: 123
    color: $black
    font: 13px $b
    text-align: center
    width: 38%
    text-transform: uppercase
  .certificate__code
    position: absolute
    left: 50%
    transform: translateX(-50%)
    top: 21%
    z-index: 123
    color: $black
    font: 13px $b
    text-transform: uppercase
  .certificate__date-of-birth
    position: absolute
    right: 25%
    bottom: 71.5%
    z-index: 123
    color: $black
    font: 13px $b
    text-transform: uppercase
  .certificate__type
    position: absolute
    right: 11.2%
    top: 32.1%
    z-index: 123
    color: $black
    font: 13px $b
    text-align: center
    width: 36%
    text-transform: uppercase
  .certificate__place
    position: absolute
    left: 9%
    top: 31.1%
    z-index: 123
    color: $black
    font: 13px/13px $b
    text-align: center
    width: 36%
    text-transform: uppercase
  .certificate__date
    position: absolute
    left: 12%
    top: 37%
    z-index: 123
    color: $black
    font: 13px $b
    text-align: center
    width: 23%
    text-transform: uppercase
  .certificate__valid
    position: absolute
    left: 54.5%
    transform: translateX(-50%)
    top: 37%
    z-index: 123
    color: $black
    font: 13px $b
    text-align: center
    width: 23%
    text-transform: uppercase

@page
  size: A4
  margin: 0
