
  
.create-official-report-page
  .scanner-loader
    display: none
  &__row
    margin-bottom: 30px
    display: flex
    justify-content: space-between
    align-items: center
  &__title
    margin-bottom: 30px!important
  &__field
    margin-bottom: 20px!important
  &__list
    margin-top: 20px
  &__textarea
    width: 100%
    height: 150px!important
    resize: none
    margin-bottom: 20px
  &--scanned
    .scanner-loader
      display: block
      width: 100%
      height: 100%
      background: rgba(0, 0, 0, 0.2)
      position: fixed
      left: 0
      top: 0
      height: 100vh
      display: flex
      z-index: 99
      align-items: center
      justify-content: center
      &-spinner
        width: 150px!important
        height: 150px!important