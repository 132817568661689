.certificate-history-modal
  max-width: 767px
  margin: 30px auto
  display: inline-block
  vertical-align: middle
  position: relative
  background: #fff
  width: 100%
  border-radius: 8px
  overflow: hidden
  padding: 55px 25px 35px