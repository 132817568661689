@import '~assets/styles/variables.sass'
.sign-in
  .sign-in__phone
    margin-bottom: 27px
    &--error
      border-color: #ff0000!important
      &::placeholder
        color: #ff0000
    @media (max-width: $sm)
      margin-bottom: 22px
  .sign-in__field
    margin-bottom: 27px
    @media (max-width: $sm)
      margin-bottom: 22px
  &__title
    font: 3.6rem $b
    margin-bottom: 40px
    @media (max-width: $sm)
      font-size: 3rem
      margin-bottom: 25px
  &__links
    justify-content: space-between
    align-items: center
    display: flex
    margin-bottom: 35px
  &__link
    font: 1.4rem $r
    color: $black
    text-decoration: none
    color: #4ACBCE
    transition: .3s all ease
    cursor: pointer
    &:hover
      opacity: .8
