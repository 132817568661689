@import '~assets/styles/variables.sass'
.footer
  display: block
  width: 100%
  padding: 35px 0
  background: #003956
  &__row
    display: flex
    align-items: center
    justify-content: space-between
  &__address
    font: 1.6rem/2rem $r
    color: $white
  &__copyright
    font: 1.2rem $m
    color: $white