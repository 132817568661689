$sm: 767px
$md: 991px
$lg: 1199px
$xl: 1440px
$xxl: 1680px

$r: 'OpenSans-Regular'
$m: 'OpenSans-Semibold'
$b: 'OpenSans-Bold'
$h: 'HelveticaNeueCyr-Heavy'
$ab: 'Arial-BoldMT'

$white: #fff
$black: #000
$dark-blue: #003956
$dark-green: #1a6b7d
$red: #ff0000
