@import '~assets/styles/variables.sass'
  
.official-report-by-id-page
  &__row
    margin-bottom: 30px
    display: flex
    justify-content: space-between
    align-items: center
    button
      &:not(:first-child)
        margin-left: 10px
  &__title
    margin-bottom: 30px!important
  &__field
    margin-bottom: 20px!important
  &__list
    margin-top: 20px
  &__textarea
    width: 100%
    height: 150px!important
    resize: none
    margin-bottom: 20px
    font: 1.6rem/2rem $r
    padding: 10px
  &__subtitle
    margin-bottom: 15px!important
  &__container
    display: flex
    align-items: center
    flex-wrap: wrap
    margin: 0 -10px
    margin-bottom: 15px
  &__btn
    padding: 0 10px
    white-space: nowrap
    position: relative
    &:not(:last-child)
      &::before
        content: ''
        position: absolute
        right: -15px
        top: 50%
        transform: translateY(-50%)
        border: 10px solid transparent
        border-left-color: $dark-blue

  &__btn-in
    border-radius: 5px
    padding: 7px
    background: $dark-blue
    color: $white
    font: 1.4rem $m
  &__btn-date
    font: 1.2rem/1.5rem $r
    color: $white
    margin-bottom: 5px
  &__modal
    @import '../../assets/styles/variables'
  &__modal
    position: fixed
    left: 0
    top: 0
    width: 100%
    height: 100%
    background: rgba(0, 0, 0, .6)
    padding: 20px
    z-index: 30
    text-align: center
    overflow-y: auto
    &::before
      content: ""
      display: inline-block
      vertical-align: middle
      height: 100%
      width: 0
    .show-file-modal--close
      left: 15px
      top: 15px

  &__modal-in
    max-width: 500px
    background: $white
    border-radius: 16px
    position: relative
    margin: 30px auto
    display: inline-block
    vertical-align: middle
    width: 100%
    text-align: left