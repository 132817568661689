@import ~assets/styles/variables
.file-uploader
  border: 1px solid rgba(0,0,0, .7)
  border-radius: 8px
  text-align: center
  padding: 20px
  height: 100%
  display: flex
  flex-direction: column
  justify-content: space-between
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)
  &--error
    border-color: $red
    h5
      color: $red
  &__buttons
    margin-top: 15px!important
  &__scanner
    background-color: $dark-blue!important
    border-color: $dark-blue!important
    & svg
      width: auto!important
      height: 25px!important
      path
        fill: $white
  h5
    margin-bottom: 12px
    text-overflow: ellipsis
    overflow: hidden
    -webkit-line-clamp: 2
    display: -webkit-box
    -webkit-box-orient: vertical
  &__picture-container
    width: 80px
    height: 80px
    margin: 0 auto
    overflow: hidden
    margin-top: auto
    display: flex
    align-items: center
    position: relative
    &::after
      content: ""
      display: block
      padding-bottom: 100%
    &--edit
      cursor: pointer
      &:hover::before
        content: ""
        left: 0
        top: 0
        position: absolute
        width: 100%
        height: 100%
        background: rgba(255, 255, 255, 0.3) url("~assets/images/zoom.svg") no-repeat center center
        background-size: 40px
        z-index: 2
  &__picture
    object-fit: cover
    width: 100%
    height: 100%
    &-loader
      margin: 0 auto
  &__text-file
    cursor: pointer
    background: none
    border: none
    font: 1.5rem $b
    color: #1a6b7d
    background-color: $dark-green!important
    border-color: $dark-green!important
    text-transform: uppercase
    transition: .3s all ease
    & svg
      width: auto!important
      height: 25px!important
      path
        fill: $white
    &:hover
      opacity: .8

  &__certificate
    font-size: 11px!important