@import '~assets/styles/variables.sass'

.transactions-page
  &__row
    margin-bottom: 20px
  &__button
    width: 100%
    &--exel
      margin-bottom: 25px!important
  &__form
    margin-bottom: 20px
  &__total
    margin-bottom: 25px
    font: 2rem $m
  &__button
    min-width: 110px
    &:last-child
      margin-top: 10px