@import '~assets/styles/variables.sass'


.checkbox
  display: inline-block
  cursor: pointer
  &__in
    display: flex
    align-items: center
  &__input
    display: none
    &:checked + .checkbox__item
      &::before
        content: ""
        background: $dark-blue url('~assets/images/check.svg') center center no-repeat
        background-size: 15px
        position: absolute
        left: 0
        top: 0
        width: 100%
        height: 100%
  &__item
    width: 20px
    height: 20px
    border-radius: 3px
    border: 1px solid rgba(0,0,0, .8)
    transition: .3s all ease
    position: relative
    &:hover
      border-color: $dark-blue