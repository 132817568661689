@import ~assets/styles/variables

.main-button
  display: block
  width: 40px
  height: 40px
  margin-left: auto
  &__icon
    width: 100%!important
    height: 100%!important
    border-radius: 5px
    path
      fill: $dark-blue
