.application-history-page
  &__row
    display: flex
    align-items: center
    margin-bottom: 20px
    grid-gap: 15px
  &__button
    margin-left: auto!important
    display: block!important
  &__link
    text-decoration: none
  &__row
    display: flex
    align-items: center
    .application-history-page__link
      margin-left: 10px
  &__success, &__error
    width: 25px!important
    height: 25px!important
  &__success path
    fill: green
  &__error path
    fill: red

@media print
  .application-history-page
    &__paper
      display: none
    &__row
      display: none
