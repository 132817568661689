@import '~assets/styles/variables.sass'
.forgot-password
  .forgot-password__field
    margin-bottom: 27px
    @media (max-width: $sm)
      margin-bottom: 22px
  &__title
    font: 3.6rem $b
    margin-bottom: 40px
    @media (max-width: $sm)
      font-size: 3rem
      margin-bottom: 25px
  &__button
    width: 100%!important
    font: 1.6rem $m!important
    padding: 16px 10px!important
    margin-top: 15px!important
