@import ~assets/styles/variables
.show-file-modal
  max-width: calc(50% - 16px)
  width: 100%
  height: 505px
  right: 0
  top: 55px
  background: $white
  position: absolute
  z-index: 22
  display: flex
  align-items: center
  justify-content: center
  visibility: hidden
  overflow: hidden
  opacity: 0
  @media (max-width: $xxl)
    height: 450px
  @media (max-width: $xl)
    height: 420px
  &--active
    opacity: 1
    visibility: visible
    border: 1px solid rgba(0,0,0, .7)
    border-radius: 10px
    & > div > div
      top: 50px !important
    & > div > div div:nth-child(4),
    & > div > div div:nth-child(5)
      display: none
    & > div > div div svg path
      fill: $white
      stroke: $dark-blue
  &::after
    padding-bottom: 100%
  &--close
    position: absolute
    right: 5px
    top: 5px
    width: 30px!important
    height: 30px!important
    cursor: pointer
    background: #fff
    border-radius: 50%
    z-index: 2
