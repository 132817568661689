@import '../../assets/styles/variables.sass'

.search-select
  &--error
    // .search-select__control
    //   border-color: $error300!important
    // .search-select__message
    //   color: $error500!important
  &__message
    margin-top: 6px
    display: inline-block
    font: 1.4rem/2.2rem $r
  &__label
    display: inline-block
    margin-bottom: 6px
    font: 1.4rem/2.2rem $b
    color: $black
  .search-select
    &__control
      display: flex
      border: 1px solid rgba(0,0,0, .5)
      border-radius: 6px
      overflow: hidden
      &--is-focused
        border-color: #000
    &__value-container
      padding: 9px 16px
      font: 1.6rem/2.4rem $r
      min-height: 42px
      &--is-multi
        padding: 4px
    &__input-container
      padding: 0
      border: none
      margin: 0
      &::placeholder
        font: 1.6rem/2.4rem $r
        color: $black
      &:disabled
        background: $black
    &__menu
      border: 1px solid $black
      border-radius: 6px
      overflow: hidden
      z-index: 2
      box-shadow: none
    &__option
      font: 1.4rem/2rem $r
      &--is-selected
        background: $black
        color: $white
      &:hover
        background: $black
    &__placeholder
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis