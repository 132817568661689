@import '~assets/styles/variables.sass'

.report-page
  &__title
    margin-bottom: 10px
  &__date-wrap
    display: none
  &__code
    display: none
  &__info
    display: none
  &__header
    display: flex
    align-items: center
    justify-content: space-between
  & .points
    min-width: 70px
@media print
  .report-page
    display: block
    left: 0
    right: 0
    bottom: 0
    top: 0
    width: 100%
    height: 100%
    background: $white
    z-index: 5
    padding: 0
    .MuiTableCell-root
      border: 1px solid rgba(0,0,0, .7)
      font-size: 9.8px!important
      padding: 1px 2px
    &__title
      font-size: 14px!important
      text-align: center
      padding-top: 20px
    &__row
      position: relative
      margin-bottom: 30px
    &__date-wrap
      position: absolute
      left: 0
      top: 27px
      display: inline-block
    &__header
      justify-content: center
      button
        display: none
    &__date
      border-bottom: 1px solid rgba(0,0,0, .7)
      margin-bottom: 0
      min-width: 70px
      text-align: center
      padding-bottom: 3px
    &__date-text
      font: 10px $r
      text-align: center
      color: rgba(0,0,0, .7)
    &__code
      position: absolute
      right: 0
      top: 10px
      width: 70px
      height: 70px
      display: block
      display: flex
      align-items: center
      justify-content: center
    &__info
      display: block
      font: 12px $r
      margin-top: 10px
      width: 50%
      line-height: 15px
    &__info-wrap
      position: relative
    &__wrap
      display: flex
      flex: 1
      margin-bottom: 5px
    &__text
      white-space: nowrap
    &__line
      border-bottom: 1px solid rgba(0,0,0, .6)
      flex: 1
      margin-left: 10px
      font: 11px $r
      text-transform: uppercase
      line-height: 20px
      white-space: nowrap
    .header
      display: none!important
    .footer
      display: none!important
