@import '~assets/styles/variables.sass'
.auth-template
  background: radial-gradient(circle at 50% 50%,#1a6b7d, #00496e,  $dark-blue  )
  flex: 1
  display: flex
  align-items: center
  justify-content: space-between
  padding: 60px 15px
  flex-direction: column
  position: relative
  &::before
    content: ""
    position: absolute
    left: 15%
    top: 40%
    width: 100%
    height: 100%
    background: url('~assets/images/pattern.png') right bottom no-repeat
    background-size: contain
  @media (max-width: $xl)
    padding: 45px 15px
  @media (max-width: $sm)
    padding: 30px 15px
  &__row
    max-width: 700px
    padding: 65px 45px
    background: $white
    width: 100%
    border-radius: 10px
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 50%), 0px 1px 1px 0px rgb(0 0 0 / 35%), 0px 1px 3px 0px rgb(0 0 0 / 20%)
    position: relative
    z-index: 1
    @media (max-width: $xxl)
      max-width: 650px
    @media (max-width: $xl)
      padding: 50px 35px
    @media (max-width: $sm)
      padding: 30px 15px
  &__logo
    max-width: 150px
    width: 100%
    @media (max-width: $xxl)
      max-width: 120px
    @media (max-width: $sm)
      max-width: 100px
  &__bottom
    height: 126px
    @media (max-width: $xxl)
      height: 95px
    @media (max-width: $xl)
      height: 60px
  &__header
    width: 100%
    max-width: 1600px
    margin: 0 auto
    position: relative
    display: flex
    justify-content: center
    margin-bottom: 65px
    @media (max-width: $xl)
      margin-bottom: 45px
    @media (max-width: $sm)
      margin-bottom: 35px

  &__lang
    position: absolute
    right: 35px
    top: 50%
    transform: translateY(-50%)
    @media (max-width: $sm)
      right: 15px