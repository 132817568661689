@import ~assets/styles/variables

.update-application-page
  &__row
    display: flex
    align-items: center
  &__update
    z-index: 2
    cursor: pointer
    margin-left: 7px
  &__textarea
    width: 100%
    height: 150px
    resize: none
    font: 1.6rem $r
    padding: 5px
  &__official-report
    margin-right: 15px!important
  &__actions
    display: flex
    align-items: center
    justify-content: space-between
    width: 100%
    margin: 20px 0
  &__button--status
    margin-top: 15px!important
  .scanner-loader
    display: none
  & .MuiGrid-root .MuiTextField-root label.Mui-disabled
    background-color: $white!important
  &__more
    text-decoration: none
    margin-left: auto
  &__appeal
    text-decoration: none
    width: 100%
  &__title
    display: flex
    align-items: flex-end
    grid-gap: 10px
    &-sub
      color: #9f0e0e
      &--verified
        color: #118711
  &__ticket
    display: flex
    align-items: center
    justify-content: space-between
    width: 100%
  &__status-info
    color: $dark-blue
    font: 1.2rem $b!important
    margin: 0!important
    padding: 3px 5px
    border: 2px solid $dark-blue
    border-radius: 5px
    &--red
      border-color: #ff0000
      color: #ff0000
    &--success
      color: green
      border-color: green
  &__button
    width: 100%
    text-decoration: none
    &-certificate
      background-color: $dark-green!important
      border-color: $dark-green!important
      width: 100%
      margin-bottom: 15px!important
    &--confirm
      background-color: $dark-green!important
      border-color: $dark-green!important
    &--indent
      margin-top: 20px!important
      width: 100%
      padding: 10px
      border-radius: 5px
      display: flex
      justify-content: center
      box-shadow: 0 0 8px rgba(26, 107, 125, .9)
      svg
        height: 25px
  &__header
    margin-bottom: 20px!important
    &-top
      margin-bottom: 20px!important
  &__modal-container
    position: relative
  &__tags
    display: flex
    align-items: center
    flex-wrap: wrap
    grid-gap: 15px
    margin-bottom: 15px
  &__tag
    border-radius: 5px
    background: rgba(0, 0, 0, 0.1)
    color: $dark-blue
    padding: 8px
    cursor: pointer
    &--selected
      background: #ff0000
      color: $white
  &__appeal
    height: 26.2px!important
    margin-left: 10px!important
  &__by-user
    &-row
      display: flex
      align-items: center
      margin-top: 20px
      h4
        width: 30%
      h5
        margin-left: 20px
        flex: 1
    &-errors
      &-title
        margin-top: 20px!important
      &-item
        color: #ff0000
        font-family: $b
        margin-right: 5px
    &-buttons
      margin-top: 20px
  &--scanned
    .scanner-loader
      display: block
      width: 100%
      height: 100%
      background: rgba(0, 0, 0, 0.2)
      position: fixed
      left: 0
      top: 0
      height: 100vh
      display: flex
      z-index: 99
      align-items: center
      justify-content: center
      &-spinner
        width: 150px!important
        height: 150px!important
  &__methods
    display: flex
    align-items: center
    justify-content: flex-start
    @media (max-width: $sm)
      flex-wrap: wrap
  &__pay
    width: calc(50%)
    background: none
    border: none
    cursor: pointer
    margin-top: 20px
    padding: 10px 5px
    border-radius: 5px
    display: flex
    justify-content: center
    box-shadow: 0 0 8px rgba(26, 107, 125, .9)
    @media (max-width: $sm)
      width: 100%
      margin-top: 10px
      box-shadow: 0 0 4px rgba(26, 107, 125, .9)
    img
      display: inline-block
      height: 27px
      @media (max-width: $sm)
        height: 20px
      &:nth-child(2)
        margin-left: 10px
        
    &--payme
      margin-right: 20px
      @media (max-width: $sm)
        margin-right: 0
      svg
        height: 25px
        @media (max-width: $sm)
          height: 20px
  