@import '~assets/styles/variables.sass'

.summary-page
  &__title
    margin-bottom: 20px!important
  &__form
    margin-bottom: 25px
  &__button
    width: 100%
  &__link
    text-decoration: none
    font-family: $b
    color: $dark-blue
  &__check
    path
      fill: green
  &__error
    path
      fill: #ff0000

  