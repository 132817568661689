.eavtotalim-page
  &__form
    margin: 20px 0
  &__success, &__error
    width: 25px!important
    height: 25px!important
  &__success path
    fill: green
  &__error path
    fill: red
  &__td
    display: flex
    grid-gap: 10px
