@import '~assets/styles/variables.sass'

.protocol-page
  &__title
    margin-bottom: 10px
  &__date-wrap
    display: none
  &__code
    display: none
  &__info
    display: none
// @media print
//   .protocol-page
//     position: absolute
//     left: 0
//     top: 0
//     width: 100%
//     height: 100%
//     background: $white
//     z-index: 5
//     padding: 35px 20px
//     .MuiTableCell-root
//       border: 1px solid rgba(0,0,0, .7)
//       font-size: 10px!important
//       padding: 6px 4px
//     &__title
//       font-size: 14px!important
//       text-align: center
//       padding-top: 35px
//     &__row
//       position: relative
//       margin-bottom: 60px
//     &__date-wrap
//       position: absolute
//       left: 0
//       top: 27px
//       display: inline-block
//     &__date
//       border-bottom: 1px solid rgba(0,0,0, .7)
//       margin-bottom: 0
//       min-width: 70px
//       text-align: center
//       padding-bottom: 3px
//     &__date-text
//       font: 10px $r
//       text-align: center
//       color: rgba(0,0,0, .7)
//     &__code
//       position: absolute
//       right: 0
//       top: 0
//       width: 80px
//       height: 80px
//       border: 1px solid rgba(0,0,0, .7)
//       display: block
//       display: flex
//       align-items: center
//       justify-content: center
//     &__info
//       display: block
//       font: 12px $r
//       margin-top: 10px
//       width: 50%
//       line-height: 15px
//     &__wrap
//       display: flex
//       flex: 1
//       margin-bottom: 5px
//     &__line
//       border-bottom: 1px solid rgba(0,0,0, .6)
//       flex: 1
//       margin-left: 2px