@import ~assets/styles/variables

.create-application-page
  position: relative
  .scanner-loader
    display: none
  &__button
    width: 100%
    text-decoration: none
  &__button-wrap
    margin-top: 25px!important
  &__header
    margin-bottom: 20px
  &__phone
    &--error
      border-color: #ff0000!important
      &::placeholder
        color: #ff0000
  &__files
    margin-bottom: 20px!important
  &__search-container
    position: relative
  &__search-input input
    padding-right: 35px!important
  &__search-button
    position: absolute
    right: 10px
    top: 16px
    bottom: 0
    width: 30px
    height: 30px
    margin: auto 0
    z-index: 10
    cursor: pointer
    svg
      width: 100%!important
      height: 100%!important
  &--scanned
    .scanner-loader
      display: block
      width: 100%
      height: 100%
      background: rgba(0, 0, 0, 0.2)
      position: fixed
      left: 0
      top: 0
      height: 100vh
      display: flex
      z-index: 99
      align-items: center
      justify-content: center
      &-spinner
        width: 150px!important
        height: 150px!important
