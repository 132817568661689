@import ~assets/styles/variables

.user-queue-page
  background: radial-gradient(circle at 50% 50%,#1a6b7d, #00496e, $dark-blue )
  min-height: 100vh
  padding: 30px 0
  width: 100%
  padding: 25px
  overflow: hidden
  position: relative
  display: flex
  flex-direction: column
  &::before
    content: ""
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    background: url('~assets/images/logo-svg.svg') center center no-repeat
    background-size: 33%
    opacity: .1
  &__header
    border-bottom: 2px solid $white
    padding-bottom: 20px
    width: 100%
    display: flex
    justify-content: space-between
  &__container
    pointer-events: none
    flex: 1
    display: flex
    flex-direction: column
  &__input
    position: absolute!important
    left: 0
    top: -200%
    opacity: 0
  &__title
    color: $white
  &__confirm
    background: #ff0000
    border-radius: 7px
    padding: 35px
    position: fixed
    left: 50%
    top: 50%
    z-index: 99
    transform: translate(-50%, -50%)
    max-width: 700px
    text-align: center
    width: 100%
  &__text
    font: 2rem $b
    text-transform: uppercase
    color: $white
