.update-ticket-page
  .MuiTypography-h3
    margin-bottom: 25px!important
    transform: 0!important
  &__container
    margin-bottom: 20px!important
    display: none
    &--active
      display: flex
  &__button
    margin-bottom: 20px
  &__item
    position: relative
    overflow: hidden