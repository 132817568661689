@import '~assets/styles/variables.sass'

.dropdown
  text-transform: uppercase
  position: relative
  &:hover
    .dropdown__list
      opacity: 1
      visibility: visible
      z-index: 100
      text-align: center
  &__label
    min-width: 150px
    height: 38px
    background: $dark-green
    display: flex
    align-items: center
    justify-content: center
    font: 1.4rem $r
    color: $white
    padding: 5px 7px
  &__list
    position: absolute
    left: 0
    top: 100%
    width: 100%
    transition: .3s all ease
    opacity: 0
    visibility: hidden
  &__item
    display: flex
    align-items: center
    justify-content: center
    font: 1.3rem $r
    color: $white
    background: #2f9ba6
    padding: 4px
    transition: .3s all ease
    cursor: pointer
    &:hover
      background: #50c4ca
      
