@import ~assets/styles/variables
.applications-page
  position: relative
  &__header
    margin-bottom: 10px
  &__item
    &--COMPLETED,
    &--CREATED
      color: green!important
    &--P_PAYMENT,
    &--T_PAYMENT,
    &--NOT_VERIFIED
      color: red!important
    &--T_EXAM,
    &--P_EXAM
      color: yellow
  &__word-icon
    width: 20px!important
    height: 20px!important
    path
      fill: $dark-green
