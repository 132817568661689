@import ~assets/styles/variables

.appeal-modal
  text-align: left
  max-width: 700px
  margin: 30px auto
  display: inline-block
  vertical-align: middle
  position: relative
  background: $white
  width: 100%
  border-radius: 8px
  overflow: hidden
  padding: 35px
  &__close
    position: absolute
    right: 15px
    top: 15px
    cursor: pointer