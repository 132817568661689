@import ~assets/styles/variables

.update-category-statuses
  max-width: 700px
  margin: 30px auto
  display: inline-block
  vertical-align: middle
  position: relative
  background: $white
  width: 100%
  border-radius: 8px
  overflow: hidden
  padding: 35px
  &__form
    margin-top: 15px!important
  &__field
    text-align: left
