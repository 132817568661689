@import '~assets/styles/variables.sass'
.header
  padding: 20px 0
  background: #003956
  display: block
  @media (max-width: $xxl)
    padding: 18px 0
  &__logo-link
    display: block
  &__logo
    max-width: 75px
    width: 100%
    @media (max-width: $xxl)
      max-width: 70px
    @media (max-width: $sm)
      max-width: 45px
  &__in
    display: flex
    align-items: center
    justify-content: space-between
  &__actions
    display: flex
    align-items: center
  &__logout
    cursor: pointer
    @media (max-width: $xl)
      max-width: 33px
  &__lang
    margin-right: 20px
  &__user
    margin-right: 20px
    font: 1.5rem $r
    color: $white
    text-align: center
  &__dropdown
    margin-right: 20px
