.category-statuses
  &__btn
    display: inline-block!important
    width: auto!important
    padding: 0!important
    border: none!important
    min-width: 1px!important
  &__buttons
    display: flex
    justify-content: flex-end
    margin-bottom: 15px
